import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["removable"];
  static classes = ["remove"];

  remove(event) {
    console.log("remove");
    this.removableTargets.forEach((t) => t.classList.remove(this.removeClass));
  }
}
